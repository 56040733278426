<template>
  <el-dialog
    title="Conferência do Resultado"
    v-model="shouldShow"
    destroy-on-close
    width="85%"
  >
    <el-table
      stripe
      :data="CashierDataCompiled"
      :span-method="showTitle"
      style="width: 100%; z-index: 0"
      :cell-style="() => 'text-align:start;'"
    >
      <el-table-column label="nome" prop="label"></el-table-column>
      <el-table-column
        label="caixa"
        :formatter="(r) => currencyFormatter.format(Number(r.cashier) || 0)"
        prop="cashier"
      ></el-table-column>
      <el-table-column
        label="encarregado"
        :formatter="(r) => currencyFormatter.format(Number(r.checker) || 0)"
        prop="checker"
      ></el-table-column>
      <el-table-column
        label="resultado"
        :formatter="(r) => currencyFormatter.format(Number(r.result) || 0)"
        prop="result"
      ></el-table-column>
    </el-table>
  </el-dialog>
</template>
<script>
//import BaseInput from "../../components/BaseInput.vue";
import { ElNotification, ElMessageBox } from "element-plus";

export default {
  props: ["cashier", "showModal"],
  //components: { BaseInput },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      firms: null,
      cahiser_: this.cashier,
      currencyFormatter: new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }),
      dateFormatter: new Intl.DateTimeFormat("pt-BR", {
        dateStyle: "short",
        timeStyle: "short",
      }),
    };
  },
  watch: {
    cashier(v) {
      this.cashier_ = v;
      if (v.firm) {
        this.firms = [v.firm];
        this.cashier_.firm_id = v.firm?.uid;
      }
    },
  },
  computed: {
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
    TotalSells() {
      const total = Number(this.cashier_?.total_cashier) || 0;
      const withdrawal = Number(this.cashier_?.withdrawal) || 0;
      //const discount = Number(this.discount) || 0;
      const start = Number(this.cashier_?.start) || 0;
      return Math.max(total + withdrawal - start, 0);
    },
    CashierDataCompiled() {
      const total_global = {
        label: "GERAL:",
        cashier:
          (Number(this.TotalSells) || 0) +
          (Number(this.cashier.discount) || 0) +
          (Number(this.cashier.canceled) || 0) +
          (Number(this.cashier.start) || 0),
        checker: Number(this.cashier.check_total_global) || 0,
      };
      total_global.result = total_global.cashier - total_global.checker;

      const total_finalize = {
        label: "FINALIZADORA:",
        cashier: Number(this.TotalSells) || 0,
        checker: Number(this.cashier.check_total_sells) || 0,
      };

      total_finalize.result =
        Number(this.TotalSells) - Number(this.cashier?.check_total_sells);

      const total_withdrawal = {
        label: "SANGRIA:",
        cashier: Number(this.cashier?.withdrawal) || 0,
        checker: Number(this.cashier?.check_withdrawal) || 0,
      };
      total_withdrawal.result =
        Number(this.cashier?.withdrawal) -
        Number(this.cashier?.check_withdrawal);

      const cashier = {
        label: "CAIXA:",
        isTitle: true,
      };

      const total_money = {
        label: "Dinheiro(Moeda + Papel + Despesa)",
        cashier:
          (Number(this.cashier.money) || 0) +
          (Number(this.cashier.coin) || 0) +
          (Number(this.cashier.expenses) || 0),
        checker: Number(this.cashier.check_money) || 0,
      };
      total_money.result = total_money.cashier - total_money.checker;

      const total_credit_debit = {
        label: "Cartão (Débito e Crédito)",
        cashier: Number(this.cashier.debit) + Number(this.cashier.credit) || 0,
        checker:
          (Number(this.cashier.check_credit) || 0) +
          (Number(this.cashier.check_debit) || 0),
      };
      total_credit_debit.result =
        total_credit_debit.cashier - total_credit_debit.checker;

      const total_debts = {
        label: "Convênio",
        cashier: Number(this.cashier.debts) || 0,
        checker: Number(this.cashier.check_debts) || 0,
      };
      total_debts.result = total_debts.cashier - total_debts.checker;

      const total_ticket = {
        label: "Ticket",
        cashier: Number(this.cashier.ticket) || 0,
        checker: Number(this.cashier.check_ticket) || 0,
      };
      total_ticket.result = total_ticket.cashier - total_ticket.checker;

      const total = {
        label: "RESULTADO",
        cashier: Number(this.cashier.total_cashier) || 0,
        checker: Number(this.cashier.check_total) || 0,
      };
      total.result = total.cashier - total.checker;

      return [
        total_global,
        total_finalize,
        total_withdrawal,
        cashier,
        total_money,
        total_credit_debit,
        total_debts,
        total_ticket,
        total,
      ];
    },
  },
  methods: {
    showTitle({ row }) {
      if (row.isTitle) return [1, 4];
      else return [1, 1];
    },
    fetchFirms(v) {
      if (v)
        fetch(`${this.$store.state.apiUrl}firms`, {
          credentials: "include",
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            else return response.text();
          })
          .then((json) => {
            this.firms = json;
            this.$emit("fetch-firms", json);
          });
    },
    updateSilent() {
      this.updateCashier(this.cashier_, true);
    },
    shouldAllowEditCashierInput() {
      return ["checked"].includes(this.cashier_.status);
    },
    acceptClosing() {
      this.updateCashier({
        uid: this.cashier?.uid,
        status: this.cashier?.status != "checked" ? "checked" : "completed",
        checked_by: this.$store.state?.user?.uid,
      });
    },
    async rejectCashier() {
      const rejection_reason = await ElMessageBox.prompt(
        "Insira uma breve descrição do motivo da rejeição",
        "Motivo da rejeição",
        {
          confirmButtonText: "Rejeitar",
          cancelButtonText: "Cancelar",
        }
      )
        .then((v) => v.value)
        .catch(() => {});
      this.updateCashier({
        ...(this.cashier_ || {}),
        status: "rejected",
        rejection_reason,
      });
    },
    shouldBlockUpdate() {
      return ["completed"].includes(this.cashier?.status);
    },
    updateCashier(cashier, silent = false) {
      if (cashier)
        fetch(`${this.$store.state.apiUrl}cashiers/${cashier.uid}`, {
          credentials: "include",
          method: "PUT",
          body: JSON.stringify(cashier),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then(async (response) => {
            if (response.status == 200) return await response.json();
            else throw await response.json();
          })
          .then(
            () => {
              if (!silent) {
                this.$emit("should-update");
                ElNotification.success({
                  title: `Fechamento de caixa alterado com sucesso!`,
                  position: "bottom-right",
                });
                this.$emit("close-modal");
              }
            },
            (e) => {
              if (!silent) {
                ElNotification.error({
                  title: "Ocorreu um erro",
                  message: e.message,
                  position: "bottom-right",
                });
              }
            }
          )
          .finally(() => (this.isLoadingSave = false));
    },
  },
  name: "CashierResultInfoModal",
};
</script>
<style>
.el-button {
  max-height: 36px !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.mt-1 {
  margin-top: 4px;
}
.stretch-width {
  width: 100% !important;
}
</style>