<template>
  <el-dialog
    title="Detalhes do fechamento de caixa"
    v-model="shouldShow"
    destroy-on-close
    width="85%"
  >
    <el-descriptions direction="vertical" border :column="6">
      <el-descriptions-item>
        <template #label> Empresa: </template>
        <div v-if="!shouldAllowEditCashierInput()">
          {{ cashier?.firm?.name }}
        </div>
        <el-select
          v-else
          remote
          :loading="!firms"
          @visible-change="fetchFirms"
          value-key="uid"
          @change="updateSilent()"
          v-model="cashier_.firm_id"
          size="medium"
        >
          <el-option
            v-for="item in firms"
            :key="item.uid"
            :label="item.name"
            :value="item.uid"
          >
          </el-option>
        </el-select>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Operador: </template>
        <div v-if="!shouldAllowEditCashierInput()">
          {{ cashier?.op }}
        </div>
        <base-input
          v-else
          v-model="cashier_.op"
          v-on:blur="updateSilent()"
        ></base-input>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Data: </template>
        <div v-if="!shouldAllowEditCashierInput()">
          {{ dateFormatter.format(new Date(cashier?.closed_at)) }}
        </div>
        <el-date-picker
          v-else
          v-on:blur="updateSilent()"
          v-model="cashier_.closed_at"
          type="date"
          :disabledDate="(d) => new Date(d) > new Date()"
          size="medium"
          format="DD/MM/YYYY"
        >
        </el-date-picker>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Reforço: </template>
        <div v-if="!shouldAllowEditCashierInput()">
          {{ currencyFormatter.format(Number(cashier?.start)) }}
        </div>
        <base-input
          v-else
          v-model="cashier_.start"
          type="money"
          v-on:blur="updateSilent()"
        ></base-input>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Moeda: </template>
        <div v-if="!shouldAllowEditCashierInput()">
          {{ currencyFormatter.format(Number(cashier?.coin)) }}
        </div>
        <base-input
          v-else
          v-model="cashier_.coin"
          type="money"
          v-on:blur="updateSilent()"
        ></base-input>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Dinheiro: </template>
        <div v-if="!shouldAllowEditCashierInput()">
          {{ currencyFormatter.format(Number(cashier?.money)) }}
        </div>
        <base-input
          v-else
          v-model="cashier_.money"
          type="money"
          v-on:blur="updateSilent()"
        ></base-input>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Cartão de Crédito: </template>
        <div v-if="!shouldAllowEditCashierInput()">
          {{ currencyFormatter.format(Number(cashier?.credit)) }}
        </div>
        <base-input
          v-else
          v-model="cashier_.credit"
          type="money"
          v-on:blur="updateSilent()"
        ></base-input>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Cartão de Débito: </template>
        <div v-if="!shouldAllowEditCashierInput()">
          {{ currencyFormatter.format(Number(cashier?.debit)) }}
        </div>
        <base-input
          v-else
          v-model="cashier_.debit"
          type="money"
          v-on:blur="updateSilent()"
        ></base-input>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> PIX: </template>
        <div v-if="!shouldAllowEditCashierInput()">
          {{ currencyFormatter.format(Number(cashier?.pix)) }}
        </div>
        <base-input
          v-else
          v-model="cashier_.pix"
          type="money"
          v-on:blur="updateSilent()"
        ></base-input>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Ticket: </template>
        <div v-if="!shouldAllowEditCashierInput()">
          {{ currencyFormatter.format(Number(cashier?.ticket)) }}
        </div>
        <base-input
          v-else
          v-model="cashier_.ticket"
          type="money"
          v-on:blur="updateSilent()"
        ></base-input>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Entregas: </template>
        <div v-if="!shouldAllowEditCashierInput()">
          {{ currencyFormatter.format(Number(cashier?.delivery)) }}
        </div>
        <base-input
          v-else
          v-model="cashier_.delivery"
          type="money"
          v-on:blur="updateSilent()"
        ></base-input>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Descontos: </template>
        <div v-if="!shouldAllowEditCashierInput()">
          {{ currencyFormatter.format(Number(cashier?.discount)) }}
        </div>
        <base-input
          v-else
          v-model="cashier_.discount"
          type="money"
          v-on:blur="updateSilent()"
        ></base-input>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Despesas: </template>
        <div v-if="!shouldAllowEditCashierInput()">
          {{ currencyFormatter.format(Number(cashier?.expenses)) }}
        </div>
        <base-input
          v-else
          v-model="cashier_.expenses"
          type="money"
          v-on:blur="updateSilent()"
        ></base-input>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Convênio: </template>
        <div v-if="!shouldAllowEditCashierInput()">
          {{ currencyFormatter.format(Number(cashier?.debts)) }}
        </div>
        <base-input
          v-else
          v-model="cashier_.debts"
          type="money"
          v-on:blur="updateSilent()"
        ></base-input>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Cancelamentos: </template>
        <div v-if="!shouldAllowEditCashierInput()">
          {{ currencyFormatter.format(Number(cashier?.canceled)) }}
        </div>
        <base-input
          v-else
          v-model="cashier_.canceled"
          type="money"
          v-on:blur="updateSilent()"
        ></base-input>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Sangria: </template>
        <div v-if="!shouldAllowEditCashierInput()">
          {{ currencyFormatter.format(Number(cashier?.withdrawal)) }}
        </div>
        <base-input
          v-else
          v-model="cashier_.withdrawal"
          type="money"
          v-on:blur="updateSilent()"
        ></base-input>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Total do Caixa: </template>
        <div v-if="!shouldAllowEditCashierInput()">
          {{ currencyFormatter.format(Number(cashier?.total_cashier)) }}
        </div>
        <base-input
          v-else
          v-model="cashier_.total_cashier"
          type="money"
          v-on:blur="updateSilent()"
        ></base-input>
      </el-descriptions-item>
    </el-descriptions>
    <form>
      <el-divider></el-divider>
      <el-row style="margin-top: -8px" type="flex" justify="start">
        <h5>CONFERÊNCIA DO ENGARREGADO</h5>
      </el-row>
      <el-row :gutter="4">
        <el-col :md="8"
          ><base-input
            v-on:blur="updateSilent()"
            :disabled="shouldBlockUpdate()"
            v-model="cashier_.sells"
            mask="#*"
            label="Quantidade de Vendas:"
          ></base-input>
        </el-col>
        <el-col :md="8">
          <base-input
            v-model="cashier_.sells_start"
            :disabled="shouldBlockUpdate()"
            v-on:blur="updateSilent()"
            mask="#*"
            label="Nº de Vendas Inicial:"
          ></base-input>
        </el-col>
        <el-col :md="8">
          <base-input
            v-on:blur="updateSilent()"
            :disabled="shouldBlockUpdate()"
            v-model="cashier_.sells_end"
            mask="#*"
            label="Nº de Vendas Final:"
          ></base-input>
        </el-col>
      </el-row>
      <el-row :gutter="4">
        <el-col :md="8"
          ><base-input
            v-on:blur="updateSilent()"
            :disabled="shouldBlockUpdate()"
            type="money"
            :minimumFractionDigits="3"
            v-model="cashier_.meat_sold"
            label="Qtd de Carne:"
          ></base-input
        ></el-col>
        <el-col :md="8">
          <base-input
            :disabled="shouldBlockUpdate()"
            v-on:blur="updateSilent()"
            type="money"
            :minimumFractionDigits="3"
            v-model="cashier_.bone_sold"
            label="Qtd de Osso:"
          ></base-input
        ></el-col>
        <el-col :md="8">
          <base-input
            :disabled="shouldBlockUpdate()"
            v-on:blur="updateSilent()"
            type="money"
            :minimumFractionDigits="3"
            v-model="cashier_.fat_sold"
            label="Qtd de Sebo:"
          ></base-input
        ></el-col>
      </el-row>
      <el-row style="margin-top: 16px" type="flex" justify="start">
        <div>Geral:</div>
      </el-row>
      <base-input
        v-on:blur="updateSilent()"
        :disabled="shouldBlockUpdate()"
        v-model="cashier_.check_total_global"
        type="money"
        label="Total:"
      ></base-input>
      <el-row style="margin-top: 16px" type="flex" justify="start">
        <div>Finalizadora:</div>
      </el-row>
      <base-input
        v-on:blur="updateSilent()"
        :disabled="shouldBlockUpdate()"
        v-model="cashier_.check_total_sells"
        type="money"
        label="Total de Vendas:"
      ></base-input>
      <el-row style="margin-top: 16px" type="flex" justify="start">
        <div>Sangria:</div>
      </el-row>
      <base-input
        type="money"
        :disabled="shouldBlockUpdate()"
        v-on:blur="updateSilent()"
        v-model="cashier_.check_withdrawal"
        label="Sangria:"
      ></base-input>

      <el-divider></el-divider>
      <el-row style="margin-top: -8px" type="flex" justify="start">
        <h5>TOTAL EM CAIXA</h5>
      </el-row>
      <el-row :gutter="4">
        <el-col :md="8"
          ><base-input
            type="money"
            :disabled="shouldBlockUpdate()"
            v-on:blur="updateSilent()"
            v-model="cashier_.check_money"
            label="Dinheiro:"
          ></base-input
        ></el-col>
        <el-col :md="8">
          <base-input
            type="money"
            :disabled="shouldBlockUpdate()"
            v-on:blur="updateSilent()"
            v-model="cashier_.check_debit"
            label="Cartão de Débito:"
          ></base-input>
        </el-col>
        <el-col :md="8">
          <base-input
            type="money"
            :disabled="shouldBlockUpdate()"
            v-on:blur="updateSilent()"
            v-model="cashier_.check_credit"
            label="Cartão de Crédito:"
          ></base-input>
        </el-col>
      </el-row>
      <el-row :gutter="4">
        <el-col :md="8"
          ><base-input
            :disabled="shouldBlockUpdate()"
            v-on:blur="updateSilent()"
            type="money"
            v-model="cashier_.check_debts"
            label="Convênio:"
          ></base-input
        ></el-col>
        <el-col :md="8"
          ><base-input
            :disabled="shouldBlockUpdate()"
            v-on:blur="updateSilent()"
            type="money"
            v-model="cashier_.check_ticket"
            label="Ticket:"
          ></base-input
        ></el-col>
        <el-col :md="8">
          <base-input
            :disabled="shouldBlockUpdate()"
            v-on:blur="updateSilent()"
            type="money"
            v-model="cashier_.check_pix"
            label="PIX/Boleto:"
          ></base-input
        ></el-col>
      </el-row>
      <base-input
        type="money"
        :disabled="shouldBlockUpdate()"
        v-on:blur="updateSilent()"
        v-model="cashier_.check_total"
        label="Total em Caixa:"
      ></base-input>
    </form>
    <template #footer>
      <el-row
        :justify="cashier?.status === 'rejected' ? 'end' : 'space-between'"
        type="flex"
      >
        <el-button
          type="warning"
          plain
          @click="rejectCashier"
          v-if="['created', 'checked'].includes(cashier?.status)"
          >Rejeitar</el-button
        >
        <el-button
          type="primary"
          class="button"
          @click="acceptClosing"
          v-if="['created', 'rejected', 'checked'].includes(cashier?.status)"
          >Aceitar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import BaseInput from "../../components/BaseInput.vue";
import { ElNotification, ElMessageBox } from "element-plus";

export default {
  props: ["cashier", "showModal"],
  components: { BaseInput },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      firms: null,
      cashier_: this.cashier || {},
      currencyFormatter: new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }),
      dateFormatter: new Intl.DateTimeFormat("pt-BR", {
        dateStyle: "short",
        timeStyle: "short",
      }),
    };
  },
  mounted() {
    this.$watch(
      function () {
        return Object.values(this.cashier_);
      },
      function () {
        this.updateTotal();
      },
      { deep: true }
    );
  },
  watch: {
    cashier(v) {
      this.cashier_ = v;
      if (v.firm) {
        this.firms = [v.firm];
        this.cashier_.firm_id = v.firm?.uid;
      }
    },
  },
  computed: {
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
    isNew() {
      return !("uid" in (this.event || {}));
    },
  },
  methods: {
    fetchFirms(v) {
      if (v)
        fetch(`${this.$store.state.apiUrl}firms`, {
          credentials: "include",
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            else return response.text();
          })
          .then((json) => {
            this.firms = json;
            this.$emit("fetch-firms", json);
          });
    },
    updateSilent() {
      this.updateCashier(this.cashier_, true);
    },
    shouldAllowEditCashierInput() {
      return ["checked"].includes(this.cashier_.status);
    },
    acceptClosing() {
      this.updateCashier({
        uid: this.cashier?.uid,
        status: this.cashier?.status != "checked" ? "checked" : "completed",
        checked_by: this.$store.state?.user?.uid,
      });
    },
    async rejectCashier() {
      const rejection_reason = await ElMessageBox.prompt(
        "Insira uma breve descrição do motivo da rejeição",
        "Motivo da rejeição",
        {
          confirmButtonText: "Rejeitar",
          cancelButtonText: "Cancelar",
        }
      )
        .then((v) => v.value)
        .catch(() => {});
      this.updateCashier({
        ...(this.cashier_ || {}),
        status: "rejected",
        rejection_reason,
      });
    },
    shouldBlockUpdate() {
      return ["completed"].includes(this.cashier?.status);
    },
    updateTotal() {
      let t = 0;
      t += Number(this.cashier_?.delivery) || 0;
      t += Number(this.cashier_?.debts) || 0;
      t += Number(this.cashier_?.expenses) || 0;
      //t -= Number(this.cashier_?.start) || 0;
      //t += Number(this.cashier_?.canceled) || 0;
      t += Number(this.cashier_?.coin) || 0;
      t += Number(this.cashier_?.money) || 0;
      t += Number(this.cashier_?.credit) || 0;
      t += Number(this.cashier_?.ticket) || 0;
      t += Number(this.cashier_?.debit) || 0;
      t += Number(this.cashier_?.pix) || 0;
      this.cashier_.total_cashier = Math.max(t, 0);
    },
    updateCashier(cashier, silent = false) {
      if (cashier)
        fetch(`${this.$store.state.apiUrl}cashiers/${cashier.uid}`, {
          credentials: "include",
          method: "PUT",
          body: JSON.stringify(cashier),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then(async (response) => {
            if (response.status == 200) return await response.json();
            else throw await response.json();
          })
          .then(
            () => {
              if (!silent) {
                this.$emit("should-update");
                ElNotification.success({
                  title: `Fechamento de caixa alterado com sucesso!`,
                  position: "bottom-right",
                });
                this.$emit("close-modal");
              }
            },
            (e) => {
              if (!silent) {
                ElNotification.error({
                  title: "Ocorreu um erro",
                  message: e.message,
                  position: "bottom-right",
                });
              }
            }
          )
          .finally(() => (this.isLoadingSave = false));
    },
  },
  name: "CashierCloseInfoModal",
};
</script>
<style>
.el-button {
  max-height: 36px !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.mt-1 {
  margin-top: 4px;
}
.stretch-width {
  width: 100% !important;
}
</style>